<template>
    <div>
        <vs-table
            search 
            stripe 
            border 
            description 
            :sst="true" 
            :data="table.data" 
            :max-items="table.length"
            :total="table.total" 
            @search="handleSearch" 
            @change-page="handleChangePage" 
            @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Sales ID</vs-th>
                <vs-th sort-key="">Item Request ID</vs-th>
                <vs-th sort-key="">Loading Date</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty (UOM)</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit" 
                                title="Edit" style="margin-right: 5px" @click="handleEdit(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.sales_name }}</vs-td>
                    <vs-td>{{ tr.code }}</vs-td>
                    <vs-td>{{ tr.date }}</vs-td>
                    <vs-td>{{ tr.total_sku }}</vs-td>
                    <vs-td>{{ tr.quantity_uom }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        warehouseId: {
            type: Number,
        },
        territoryId: {
            type: Number,
        },
        salesId: {
            type: Number,
        },
        status: {
            type: String,
            default: "Complete",
        },
    },
    data() {
        return {
            deleteId: null,
            action: null,
            table: this.tableDefaultState(),
            selectedRouteAssignmentToAssign: [],
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/canvas/unloading/sales-table", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        status: this.status,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: error,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleEdit(id) {
            this.$router.push({
                name: "canvas.sales-view-create",
                params: { id: id },
            });
        },
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
}
</script>