<template>
    <vx-card title="Sales - View">
        <div class="vx-input-group flex">
            <vs-tabs>
                <vs-tab label="Form for Sales">
                    <tab-temp-form></tab-temp-form>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>

<script>
import TabTempForm from "./TabTempForm.vue";
export default {
    components: {
        TabTempForm
    },
    data() {
        return {
            activeTab: "Complete",
        }
    },
    methods: {}
}
</script>